const settings = `settings {
  ... on ComponentSharedSpacing {
    spacing
  }
}`
export const modules = `
  modules {
    ... on ComponentModuleText {
      __typename
      text
      textplacement: placement
      ${settings}
    }
    ... on ComponentModuleStorelocator {
      __typename
      ${settings}
    }
    ... on ComponentModuleSlideshow {
      __typename
      delay
      arrows
      effect
      speed
      autoplay
      highresolution
      slides(pagination: { limit: 100 }) {
        title
        text
        textcolor
        buttontextcolor
        mediaAlignment
        textAlignment
        fit
        media {
          data {
            attributes {
              width
              height
              caption
              mime
              url
              hash
              ext
            }
          }
        }
        buttons {
          title
          url
          product_relation: product {
            data {
              attributes {
                slug
              }
            }
          }
          page_relation: page {
            data {
              attributes {
                slug
              }
            }
          }
          recipe_relation: recipe {
            data {
              attributes {
                slug
              }
            }
          }
          event_relation: event {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
      ${settings}
    }
    ... on ComponentModuleSplitview {
      __typename
      text
      media {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      placement
      cta {
        text
        button
        url
        product_relation: product {
          data {
            attributes {
              slug
            }
          }
        }
        page_relation: page {
          data {
            attributes {
              slug
            }
          }
        }
        article_relation: article {
          data {
            attributes {
              slug
            }
          }
        }
        event_relation: event {
          data {
            attributes {
              slug
            }
          }
        }
      }
      ${settings}
    }
    ... on ComponentModuleArticles {
      __typename
      limit
      articles {
        data {
          id
        }
      }
      ${settings}
    }    
    ... on ComponentModuleEmbed {
      __typename
      code
    }
    ... on ComponentModulePages {
      __typename
      pages {
        data {
          id
        }
      }
      ${settings}
    }
    ... on ComponentModuleProducts {
      __typename
      limit
      product_categories {
        data {
          id
        }
      }
      products {
        data {
          id
        }
      }
      carousel
      large
      ${settings}
    }
    ... on ComponentModuleEvents {
      __typename
      limit
      previous
      event_categories {
        data {
          id
        }
      }
      events {
        data {
          id
        }
      }
      ${settings}
    }
    ... on ComponentModuleRecipes {
      __typename
      limit
      recipes {
        data {
          id
        }
      }
      ${settings}
    }
    ... on ComponentModuleVideo {
      __typename
      video {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      cover {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      ${settings}
    }
    ... on ComponentModuleImages {
      __typename
      imageplacement: placement
      carousel
      images {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      ${settings}
    }
  }
`;
